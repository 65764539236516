import i18n from '../i18n'

export class EnumValue {
  constructor (value, label) {
    this.label = label
    this.value = value
    Object.freeze(this)
  }

  get name () {
    return i18n.t(this.label)
  }
}

export class EnumValueWithIcon {
  constructor (value, label, icon) {
    this.label = label
    this.value = value
    this.icon = icon
    Object.freeze(this)
  }

  get name () {
    return i18n.t(this.label)
  }
}

export class EnumValueWithExplanation {
  constructor (value, label, explanation) {
    this.label = label
    this.value = value
    this.explanation = explanation
    Object.freeze(this)
  }

  get name () {
    return i18n.t(this.label)
  }

  get definition () {
    return i18n.t(this.explanation)
  }
}

export class Enum {
  constructor () {
    throw Error('cannot instantiate enum base class')
  }

  static get enums () {
    return Object.values(this).filter(v => v instanceof EnumValue || v instanceof EnumValueWithIcon || v instanceof EnumValueWithExplanation)
  }

  static get names () {
    return this.enums.map(e => e.name)
  }

  // int -> str
  static fromInt (i) {
    return this.forInt(i)?.name ?? i18n.t('notAvailable')
  }

  // int -> str
  static labelFromInt (i) {
    return this.forInt(i)?.label ?? 'notAvailable'
  }

  // str -> int
  static toInt (s) {
    return this.forName(s)?.value ?? -1
  }

  static forInt (i) {
    return this.enums.find(e => e.value === i)
  }

  static forLabel (s) {
    return this.enums.find(e => e.label === s)
  }

  static forName (s) {
    return this.enums.find(e => e.name === s)
  }
}

export class ContractStatus extends Enum {
  static Open = new EnumValue(0, 'open')
  static Closed = new EnumValue(1, 'closed')
  static OnHold = new EnumValue(2, 'onHold')
  static Expired = new EnumValue(3, 'expired')
}

export class RecoveryType extends Enum {
  static FlatRate = new EnumValue(0, 'flatRate')
  static PercentageOfPayable = new EnumValue(1, 'percentageOfPayable')
  static RatePerTon = new EnumValue(2, 'ratePerTon')
}

export class ContractStatusReason extends Enum {
  static None = new EnumValue(0, 'none')
  static NoAccountsPayableActivitiesDefined = new EnumValue(1, 'noAccountsPayableActivitiesDefined')
  static NoAccountsReceivableActivitiesDefined = new EnumValue(2, 'noAccountsReceivableActivitiesDefined')
  static NoProductSpecified = new EnumValue(3, 'noProductsSpecified')
  static NoActivitiesDefined = new EnumValue(4, 'noActivitiesDefined')
  static WaitingForEffectiveDate = new EnumValue(5, 'waitingForEffectiveDate')
  static NoOpenDecksAtDestination = new EnumValue(6, 'noOpenDecksAtDestination')
  static ExpiredInsurance = new EnumValue(7, 'expiredInsurance')
  static ClosedByTract = new EnumValue(8, 'closedByTract')
  static OpenedByTract = new EnumValue(9, 'openedByTract')
  static MissingTransferActivity = new EnumValue(10, 'missingTransferActivity')
  static NoProductsSpecified = new EnumValue(11, 'noProductsSpecified')
  static CounterpartyNotSet = new EnumValue(12, 'counterpartyNotSet')
}

export class TicketStatus extends Enum {
  static InTransit = new EnumValue(0, 'inTransit')
  static WeighedIn = new EnumValue(1, 'weighedIn')
  static WeighedOut = new EnumValue(2, 'weighedOut')
  static Scaled = new EnumValue(3, 'scaled')
  static PendingValidation = new EnumValue(4, 'pendingValidation')
  static ReadyForPayment = new EnumValue(5, 'readyForPayment')
  static Posted = new EnumValue(6, 'posted')
  static Exported = new EnumValue(7, 'exported')
  static Recalculating = new EnumValue(50, 'recalculating')
  static Exception = new EnumValue(99, 'exception')
}

export class DeckStatus extends Enum {
  static Open = new EnumValue(0, 'open')
  static Closed = new EnumValue(1, 'closed')
}

export class ContractType extends Enum {
  static Production = new EnumValue(0, 'production')
  static WoodsSale = new EnumValue(1, 'woodsSale')
  static LogYardSale = new EnumValue(2, 'logYardSale')
  static ByproductSale = new EnumValue(3, 'byproductSale')
  static Transfer = new EnumValue(4, 'transfer')
  static ByproductPurchase = new EnumValue(5, 'byproductPurchase')
}

export class PayPeriodStatus extends Enum {
  static Closed = new EnumValue(0, 'closed')
  static Open = new EnumValue(1, 'open')
  static Inactive = new EnumValue(2, 'inactive')
  static Exported = new EnumValue(3, 'exported')
}

export class FiscalYearStatus extends Enum {
  static Closed = new EnumValue(0, 'closed')
  static Open = new EnumValue(1, 'open')
  static Inactive = new EnumValue(2, 'inactive')
}

export class CostType extends Enum {
  static Unspecified = new EnumValue(0, 'unspecified')
  static Production = new EnumValue(1, 'production')
  static Stumpage = new EnumValue(2, 'stumpage')
  static NonInventory = new EnumValue(3, 'nonInventory')
}

export class TemplateCostType extends Enum {
  static Unspecified = new EnumValue(0, 'unspecified')
  static ProductionCost = new EnumValue(1, 'productionCost')
  static StumpageCost = new EnumValue(2, 'stumpageCost')
  static nonInventoryCost = new EnumValue(3, 'nonInventoryCost')
}

export class ActivityStatus extends Enum {
  static Active = new EnumValue(0, 'active')
  static Inactive = new EnumValue(1, 'inactive')
}

export class PayOn extends Enum {
  static Gross = new EnumValue(0, 'gross')
  static Net = new EnumValue(1, 'net')
  static Defect = new EnumValue(2, 'defect')
  static NatureDefect = new EnumValue(3, 'natureDefect')
  static MismanufacturedDefect = new EnumValue(4, 'mismanufacturedDefect')
  static NotApplicable = new EnumValue(50, 'notAvailable')
}

export class PayBy extends Enum {
  static Weight = new EnumValue(1, 'weight')
  static Load = new EnumValue(2, 'load')
}

export class PayByType extends Enum {
  static Volume = new EnumValue(0, 'volume')
  static Weight = new EnumValue(1, 'weight')
  static Load = new EnumValue(2, 'load')
}

export class AccountingCategory extends Enum {
  static Payable = new EnumValue(0, 'payable')
  static Receivable = new EnumValue(1, 'receivable')
  static Accrual = new EnumValue(2, 'accrual')
}

export class TemplateAccountingCategory extends Enum {
  static AccountsPayable = new EnumValue(0, 'accountsPayable')
  static AccountsReceivable = new EnumValue(1, 'accountsReceivable')
  static Accruals = new EnumValue(2, 'accruals')
}

export class ActivityModifier extends Enum {
  static None = new EnumValue(0, 'none')
  static DistanceTraveled = new EnumValue(1, 'distanceTraveled')
}

export class TagArea extends Enum {
  static Accounts = new EnumValue(0, 'accounts')
  static LoggingAccessibility = new EnumValue(1, 'loggingAccessibility')
}

export class TicketOrigin extends Enum {
  static Loader = new EnumValue(0, 'createdOnLoader')
  static WebClient = new EnumValue(1, 'createdOnWebClient')
  static Receiver = new EnumValue(2, 'createdOnReceiver')
}

export const TicketStatusIcon = (ticketStatus, locale) => {
  const status = {
    icon: '',
    iconColor: 'gray',
    tooltipText: ''
  }

  switch (ticketStatus) {
    case 0:
      status.icon = 'mdi-truck'
      status.tooltipText = i18n.t('inTransit')
      break
    case 1:
      status.icon = 'mdi-weight'
      status.iconColor = 'gray'
      status.tooltipText = i18n.t('weighedIn')
      break
    case 2:
      status.icon = 'mdi-receipt-text-clock-outline'
      status.color = 'black'
      status.tooltipText = i18n.t('weighedOut')
      status.iconColor = 'black'
      break
    case 3:
      status.icon = 'mdi-scale'
      status.color = 'black'
      status.tooltipText = i18n.t('scaled')
      break
    case 4:
      status.iconColor = 'gray'
      status.icon = 'mdi-clock-outline'
      status.tooltipText = i18n.t('pendingValidation')
      break
    case 5:
      status.icon = 'mdi-currency-usd'
      status.tooltipText = i18n.t('readyForPayment')
      status.iconColor = 'success'
      break
    case 6:
      status.icon = 'mdi-checkbox-marked-circle'
      status.tooltipText = i18n.t('posted')
      status.iconColor = 'black'
      break
    case 7:
      status.icon = 'mdi-checkbox-marked-circle'
      status.tooltipText = i18n.t('exported')
      status.iconColor = 'success'
      break
    case 50:
      status.icon = 'mdi-calculator'
      status.tooltipText = i18n.t('recalculating')
      status.iconColor = 'grey'
      break
    default:
      status.icon = 'mdi-exclamation'
      status.tooltipText = i18n.t('exception')
      status.iconColor = 'error'
  }

  return status
}

export class WoodType extends Enum {
  static Softwood = new EnumValue(0, 'softwood')
  static Hardwood = new EnumValue(1, 'hardwood')
}

export class TimberType extends Enum {
  static Pulpwood = new EnumValue(0, 'pulpwood')
  static ChipNSaw = new EnumValue(1, 'chipnsaw')
  static Sawtimber = new EnumValue(2, 'sawtimber')
  static Poles = new EnumValue(3, 'poles')
  static Posts = new EnumValue(4, 'posts')
  static FuelwoodChips = new EnumValue(5, 'fuelwoodChips')
  static FuelwoodFirewood = new EnumValue(6, 'fuelwoodFirewood')
}

export class TractTypeCategory extends Enum {
  static Stumpage = new EnumValue(0, 'stumpage')
  static Delivered = new EnumValue(1, 'delivered')
}

export class DefectCategory extends Enum {
  static Mismanufactured = new EnumValue(0, 'mismanufactured')
  static Nature = new EnumValue(1, 'nature')
}

export class RecoveryMethods extends Enum {
  static AutoCalculated = new EnumValue(0, 'autoCalculated')
  static ManualPayment = new EnumValue(1, 'manualPayment')
}

export class AdvanceModificationTypes extends Enum {
  static BalanceChange = new EnumValue(0, 'balanceChange')
  static Payoff = new EnumValue(1, 'payoff')
}

export class DefectCollectionType extends Enum {
  static Simple = new EnumValue(0, 'simple')
  static Itemized = new EnumValue(1, 'itemized')
  static Segmented = new EnumValue(2, 'segmented')
}
export class CorrectionType extends Enum {
  static SpecifyNewTicketValues = new EnumValue(0, 'specifyNewValues')
  static RecalculateWithNewContractValues = new EnumValue(1, 'recalculateWithNewContractValues')
  static DeleteTicket = new EnumValue(2, 'deleteTicket')
}

export class CorrectionStatus extends Enum {
  static ReadyForExport = new EnumValue(0, 'readyForExport')
  static Exported = new EnumValue(1, 'exported')
}

export class CertificationCategory extends Enum {
  static Tract = new EnumValue(0, 'tract')
  static Account = new EnumValue(1, 'account')
}

export class RuntimeCounterparty extends Enum {
  static Landowner = new EnumValue(0, 'tractLandowner')
  static DefaultLogger = new EnumValue(1, 'tractDefaultLogger')
  static ContractAccount = new EnumValue(2, 'contractAccount')
  static Hauler = new EnumValue(3, 'tractHauler')
  static Supplier = new EnumValue(4, 'tractSupplier')
  static DestinationAccount = new EnumValue(5, 'destinationAccount')
  static ConsultingForester = new EnumValue(6, 'tractConsultingForester')
  static Driver = new EnumValue(7, 'ticketDriver')
}

export class ConsumptionMode extends Enum {
  static TicketBased = new EnumValue(0, 'ticketBased')
  static WeightBased = new EnumValue(1, 'weightBased')
}

export class ReceiverScaleConnectionTypes extends Enum {
  static HTTP = new EnumValue(0, 'http')
  static TCP = new EnumValue(1, 'tcp')
  static Custom = new EnumValue(2, 'custom')
}

export class ReceiverLoadCreationTypes extends Enum {
  static LogsOnly = new EnumValue(0, 'logsOnly')
  static LogsAndByproducts = new EnumValue(1, 'logsAndByproducts')
  static ByproductsOnly = new EnumValue(2, 'byproductsOnly')
}

export class ReceiverLogContractFilterTypes extends Enum {
  static Account = new EnumValue(0, 'account')
  static Tract = new EnumValue(1, 'tract')
}

export class ReceiverByproductSaleContractFilterTypes extends Enum {
  static Account = new EnumValue(0, 'account')
  static Destination = new EnumValue(1, 'destination')
}

export class ReceiverByproductPurchaseContractFilterTypes extends Enum {
  static FromAccountTract = new EnumValue(0, 'fromAccount')
  static Account = new EnumValue(1, 'account')
}

export class ReceiverLogContractLoadCountTimeFilter extends Enum {
  static Day = new EnumValue(0, 'day')
  static Week = new EnumValue(1, 'week')
}

export class ReceiverDefectCollectionBehavior extends Enum {
  static Inbound = new EnumValue(0, 'inbound')
  static Outbound = new EnumValue(1, 'outbound')
  static Unloader = new EnumValue(2, 'unloader')
}

export class ReceiverDefectCollectionUnit extends Enum {
  static Pounds = new EnumValue(0, 'lb')
  static Percentage = new EnumValue(1, 'percentage')
  static Itemized = new EnumValue(2, 'itemized')
  static Segmented = new EnumValue(3, 'segmented')
}

export class SystemDayOfWeek extends Enum {
  static Sunday = new EnumValue(0, 'sunday')
  static Monday = new EnumValue(1, 'monday')
  static Tuesday = new EnumValue(2, 'tuesday')
  static Wednesday = new EnumValue(3, 'wednesday')
  static Thursday = new EnumValue(4, 'thursday')
  static Friday = new EnumValue(5, 'friday')
  static Saturday = new EnumValue(6, 'saturday')
}

export const CorrectionSide = [
  {
    name: 'Negation of old values',
    value: 0
  },
  {
    name: 'New values',
    value: 1
  }
]

export const TractStatus = [
  {
    name: 'Initiatied',
    value: 0
  },
  {
    name: 'Cruised',
    value: 1
  },
  {
    name: 'Executed',
    value: 2
  },
  {
    name: 'Active',
    value: 3
  },
  {
    name: 'Closed',
    value: 4
  },
  {
    name: 'Archived',
    value: 5
  }
]

export const ContractModes = [
  {
    text: i18n.t('logs'),
    value: 0,
    icon: 'mdi-clipboard-outline',
    color: 'secondary'
  },
  {
    text: i18n.t('byproducts'),
    value: 1,
    icon: 'mdi-circular-saw',
    color: '#363636'
  },
  {
    text: i18n.t('transfers'),
    value: 2,
    icon: 'mdi-transfer',
    color: 'grey'
  }
]

export const CorrectionTypes = [
  {
    name: 'Recalculate with new contract values',
    value: 1
  },
  {
    name: 'Specify new ticket values',
    value: 0
  },
  {
    name: 'Delete ticket',
    value: 2
  }
]

export class IntegrationSourceType extends Enum {
  static Logs = new EnumValue(0, 'logs')
  static Byproducts = new EnumValue(1, 'byproducts')
  static TractPayments = new EnumValue(2, 'tractPayments')
  static Advances = new EnumValue(3, 'advances')
}

export class IntegrationPolarity extends Enum {
  static Negative = new EnumValue(0, 'negative')
  static Positive = new EnumValue(1, 'positive')
}

export class IntegrationStatus extends Enum {
  static NotStarted = new EnumValue(0, 'notStarted')
  static Complete = new EnumValue(1, 'complete')
  static Error = new EnumValue(2, 'error')
}

export class AuditEntryOpType extends Enum {
  static Create = new EnumValue(0, 'create')
  static Update = new EnumValue(1, 'update')
  static Delete = new EnumValue(2, 'delete')
}

export class AddressType extends Enum {
  static ShipTo = new EnumValue(0, 'shipTo')
  static BillTo = new EnumValue(1, 'billTo')
}

export class FinancialIntegrationType extends Enum {
  static None = new EnumValue(0, 'none')
  static CsvDownloads = new EnumValue(1, 'csvDownloads')
  static BusinessCentralWithBinaryStream = new EnumValue(2, 'businessCentral')
  static CsvDrop = new EnumValue(3, 'csvDrop')
}

export class AdjustingEntriesFrequency extends Enum {
  static None = new EnumValue(0, 'none')
  static MonthEnd = new EnumValue(1, 'monthEnd')
  static OnDemand = new EnumValue(2, 'onDemand')
}

// Clilent-only Enumerations
export class VarianceIndicatorLevel extends Enum {
  static None = new EnumValue(0, 'none')
  static Warning = new EnumValue(1, 'warning')
  static Error = new EnumValue(2, 'error')
}

export class UserClaims extends Enum {
  static GlobalAdmin = new EnumValue(0, 'globalAdmin')
  static ContractManager = new EnumValue(1, 'contractManager')
  static ExportManager = new EnumValue(2, 'exportManager')
  static TemplateManager = new EnumValue(3, 'templateManager')
  static ContractReviewer = new EnumValue(4, 'contractReviewer')
  static InventoryManager = new EnumValue(5, 'inventoryManager')
  static AccountManager = new EnumValue(6, 'accountManager')
  static TicketManager = new EnumValue(7, 'ticketManager')
}

export class UserClaimStatus extends Enum {
  static NotSet = new EnumValue(0, 'notSet')
  static Allow = new EnumValue(1, 'allow')
}

export class ModificationExportType extends Enum {
  static LogsTicket = new EnumValue(0, 'logsTicket')
  static ByproductTicket = new EnumValue(1, 'byproductTicket')
  static TransferTicket = new EnumValue(2, 'transferTicket')
  static LogsContract = new EnumValue(3, 'logsContract')
  static ByproductContract = new EnumValue(4, 'byproductContract')
  static TransferContract = new EnumValue(5, 'transferContract')
  static Tract = new EnumValue(6, 'tract')
  static ActivityTemplate = new EnumValue(7, 'activityTemplate')
  static DefectDefinition = new EnumValue(8, 'defectDefinition')
}

export class WidgetType extends Enum {
  static StatsCard = new EnumValueWithIcon(0, 'statsCard', 'mdi-credit-card-chip-outline')
  static BarChart = new EnumValueWithIcon(1, 'barChart', 'mdi-chart-bar')
  static StackedBarChart = new EnumValueWithIcon(2, 'stackedBarChart', 'mdi-chart-bar-stacked')
  static LineChart = new EnumValueWithIcon(3, 'lineChart', 'mdi-chart-line')
}

export class ExportBatchIntegrationStatus extends Enum {
  static Pending = new EnumValue(0, 'pending')
  static Success = new EnumValue(1, 'success')
  static Error = new EnumValue(2, 'error')
  static Retry = new EnumValue(3, 'retry')
  static Interrupted = new EnumValue(4, 'interrupted')
}

export class ExportBatchBiPumpStatus extends Enum {
  static Pending = new EnumValue(0, 'pending')
  static Success = new EnumValue(1, 'success')
  static Error = new EnumValue(2, 'error')
  static Retry = new EnumValue(3, 'retry')
}

export class AdjustingEntriesSnapshotIntegrationStatus extends Enum {
  static Pending = new EnumValue(0, 'pending')
  static Success = new EnumValue(1, 'success')
  static Error = new EnumValue(2, 'error')
  static Retry = new EnumValue(3, 'error')
}

export class AdjustingEntriesSnapshotUploadStatus extends Enum {
  static Unuploaded = new EnumValue(0, 'unuploaded')
  static Uploaded = new EnumValue(1, 'uploaded')
}

export class TransitStatus extends Enum {
  static NotSet = new EnumValue(0, 'notSet')
  static Ready = new EnumValue(1, 'ready')
  static InProgress = new EnumValue(2, 'inProgress')
  static Paused = new EnumValue(3, 'paused')
}

export class TransporterTicketCategory extends Enum {
  static None = new EnumValue(0, 'none')
  static Logs = new EnumValue(1, 'logs')
  static Byproducts = new EnumValue(2, 'byproduct')
  static All = new EnumValue(50, 'all')
}

export class ContractApprovalStatus extends Enum {
  static Approved = new EnumValue(0, 'approved')
  static PendingModification = new EnumValue(1, 'pendingModification')
  static PendingReview = new EnumValue(2, 'pendingReview')
  static Returned = new EnumValue(3, 'returned')
}

export class NotificationIdentifier extends Enum {
  static NoFiscalYear = new EnumValue(0, 'noFiscalYear')
  static FiscalYearExpiration = new EnumValue(1, 'fiscalYearExpiration')
  static NoPayPeriod = new EnumValue(2, 'noPayPeriod')
  static LastPayPeriod = new EnumValue(3, 'lastPayPeriod')
  static ContractExpiration = new EnumValueWithIcon(4, 'contractExpiration', 'mdi-altimeter')
  static InsuranceExpiration = new EnumValueWithIcon(5, 'insuranceExpiration', 'mdi-shield')
  static DeviceRegistrationExpiration = new EnumValueWithIcon(6, 'deviceRegistrationExpiration', 'mdi-tablet-android')
  static ContractModificationPendingApproval = new EnumValueWithIcon(7, 'contractModificationPendingApproval', 'mdi-file-document-check-outline')
  static ContractModificationReturned = new EnumValueWithIcon(8, 'contractModificationReturned', 'mdi-file-document-check-outline')
  static AccountCertificationExpiration = new EnumValueWithIcon(9, 'accountCertificationExpiration', 'mdi-certificate-outline')
}

export class DeckContentType extends Enum {
  static Logs = new EnumValue(0, 'logs')
  static Byproducts = new EnumValue(1, 'byproducts')
}
export class AccountFileCategory extends Enum {
  static Insurance = new EnumValue(0, 'insurance')
  static Certification = new EnumValue(1, 'certification')
}

export class CertificationStatus extends Enum {
  static None = new EnumValue(0, 'none')
  static Inactive = new EnumValue(1, 'inactive')
  static Active = new EnumValue(2, 'active')
  static Expiring = new EnumValue(3, 'expiring')
  static Expired = new EnumValue(4, 'expired')
  static Multiple = new EnumValue(50, 'multiple')

  static isCertified (val) {
    return [this.Active.value, this.Expiring.value, this.Multiple.value].includes(val)
  }
}

export class RecoveryMode extends Enum {
  static None = new EnumValueWithExplanation(0, 'none', undefined)
  static Oldest = new EnumValueWithExplanation(1, 'oldestNonTract', 'oldestAdvanceWithBalanceUnassociatedWithTract')
  static OldestOnTract = new EnumValueWithExplanation(2, 'oldestOnTract', 'oldestAdvanceWithBalanceAssociatedWithContractTract')
  static Specific = new EnumValueWithExplanation(3, 'specific', 'selectASpecificAdvanceFromWhichToRecover')
}

export class MiscPaymentType extends Enum {
  static Advance = new EnumValue(0, 'advance')
  static TractPayment = new EnumValue(1, 'tractPayment')
  static AccountPayment = new EnumValue(2, 'accountPayment')
}

export class SettlementDeliveryMode extends Enum {
  static None = new EnumValue(0, 'none')
  static Email = new EnumValue(1, 'email')
  static Print = new EnumValue(2, 'print')
  static EmailAndPrint = new EnumValue(3, 'emailAndPrint')
}

export class SettlementArchiveStatus extends Enum {
  static None = new EnumValue(0, 'none')
  static Pending = new EnumValue(1, 'pending')
  static DataArchived = new EnumValue(2, 'dataArchvied')
  static Success = new EnumValue(3, 'success')
  static Upgrade = new EnumValue(4, 'upgrade')
}

export class EmailRequestStatus extends Enum {
  static EmailRequested = new EnumValue(0, 'emailRequested')
  static BlobCopied = new EnumValue(1, 'blobCopied')
}

export class ErrorSource extends Enum {
  static WebClient = new EnumValue(0, 'WC')
  static Server = new EnumValue(1, 'SVR')
  static Unknown = new EnumValue(50, 'UNKNOWN')
}

export class BulkSettlementDeliveryMode extends Enum {
  static SinglePdf = new EnumValue(0, 'singlePdf')
  static ZippedFolder = new EnumValue(1, 'zippedFolder')
  static Email = new EnumValue(2, 'emailPayees')
}

export class HarvestUnits extends Enum {
  static Tons = new EnumValue(0, 'tons')
  static MBF = new EnumValue(1, 'mbf')
}
