<template>
  <v-autocomplete
    :data-testid="this.dataTestid"
    color="black"
    item-color="secondary"
    v-model="chosenUser"
    :label="label"
    @change="userChosen"
    :loading="usersLoading"
    :items="allUsers"
    item-text="name"
    clearable
    return-object
    :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
  >
    <template #append-outer>
      <v-icon @click="refreshUsers" color="secondary" tabindex="-1">mdi-refresh</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserAutocomplete',

  props: {
    propUserId: { type: Number },
    dataTestid: { type: String },
    label: { type: String, default: 'User' },
    fetchUsers: { type: Boolean, default: true }
  },

  data: () => ({
    chosenUser: undefined
  }),

  async created () {
    if (this.fetchUsers) await this.getAllUsers()
    if (this.propUserId) this.chosenUser = this.allUsers.find(u => u.applicationUserId === this.propUserId)
  },

  computed: {
    ...mapGetters('user', ['allUsers', 'usersLoading'])
  },

  methods: {
    ...mapActions('user', ['getAllUsers']),

    async refreshUsers () {
      await this.getAllUsers()
    },

    userChosen () {
      this.$emit('user-chosen', this.chosenUser)
    }
  }
}
</script>
