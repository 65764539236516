<template>
  <div>
    <v-row>
      <v-col>
        <v-row dense align="center">
          <v-col cols="auto">
            <span v-if="!loading" class="subtitle-1">{{ $t('landowners') }}</span>
            <v-skeleton-loader v-else type="button" max-width="90" max-height="19"/>
          </v-col>
          <v-col cols="auto">
            <Icon v-if="!loading"
              icon="mdi-plus"
              :small='false'
              tabindex="0"
              @icon-clicked="addLandowner"
              :tooltipText="$t('addLandowner')"/>
            <v-skeleton-loader v-else type="button" max-width="24" max-height="24"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-n4">
      <v-col cols="12" sm="6" lg="4" xl="3" v-for="(landowner, index) in landowners" :key="index">
        <v-card outlined rounded v-if="!loading">
          <v-card-title>
            <v-row dense align="center">
              <v-col v-if="landowner.accountId">
                <v-row no-gutters align="center">
                  <span class="subtitle-1">{{ landowner.accountName }}</span>
                </v-row>
              </v-col>
              <v-col v-else>
                <AccountAutocomplete
                class="mb-n11"
                :accountId="landowner.accountId"
                :title="$t('landOwner')"
                userSetting="landOwnerAccountAutocomplete"
                @account-chosen="landownerChosen($event, index)"
                :ref="`autocomplete-${index}`"
                :accountBlacklist="landowners.map(l => l.accountId)"
                :prohibitedSet="existingLandowners"
                :fetchTagsAndAccounts="false"/>
              </v-col>
              <v-col cols="auto">
                <v-row no-gutters>
                  <Icon
                  icon="mdi-delete-forever"
                  iconColor="error"
                  tooltipColor="error"
                  :tooltipText="$t('delete')"
                  @icon-clicked="landowners.splice(index, 1)"/>
                  <Icon
                  v-if="landowner.ownership === 0 && landowner.accountId"
                  icon="mdi-alert"
                  iconColor="error"
                  tooltipColor="error"
                  :tooltipText="$t('zeroAllocation')"/>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                reverse
                :label="$t('ownership')"
                v-model.number="landowner.ownership"
                type="number"
                hide-spin-buttons
                :rules="[rules.validPercentage(false)]"
                prefix="%"
                :ref="`input-${index}`"
                @keydown.enter="handleTextEntry(index)"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-skeleton-loader v-else type="image" max-height="150"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="subtitle-1" v-if="!loading">
          {{ $t('allocatedOwnership', { x: formatPercentage(allocatedOwnership) }) }}
          <Icon
          v-if="unallocatedOwnershipWarning || overAllocated"
          icon="mdi-alert"
          icon-color="error"
          tooltipColor="error"
          :small="false"
          :tooltipText="unallocatedOwnershipWarning ? $t('unallocatedTractOwnership', { x: formatPercentage(unallocatedOwnership) }) : $t('overAllocated')"/>
        </span>
        <v-skeleton-loader v-else type="text" max-width="220" max-height="19"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatPercentage } from '@/utils/NumericMutations'
import fieldRules from '@/utils/rules'
export default {
  name: 'TractLandowners',

  components: {
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    propLandowners: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    landowners: [],
    rules: fieldRules.rules
  }),

  computed: {
    ...mapGetters('account', ['allAccounts']),
    allocatedOwnership () {
      return Number(Number(this.landowners.reduce((total, owner) => total + (owner.accountId ? owner.ownership : 0), 0)).toFixed(3))
    },

    unallocatedOwnership () {
      return 100 - this.allocatedOwnership
    },

    unallocatedOwnershipWarning () {
      return this.allocatedOwnership < 100 && this.allocatedOwnership > 0
    },

    availableAccounts () {
      return this.allAccounts
        .filter(a => !this.landowners.some(l => l.accountId === a.accountId))
        .map(a => ({ accountId: a.accountId, accountName: a.name, ownership: 0 }))
    },

    overAllocated () {
      return this.allocatedOwnership > 100
    },

    existingLandowners () {
      return new Set(this.landowners.map(low => low.accountId))
    }
  },

  watch: {
    landowners: {
      handler (val) {
        this.$emit('ownership-changed', val)
      },
      deep: true
    },

    propLandowners: {
      handler () {
        if (this.landowners.length !== this.propLandowners.length ||
        !this.landowners.every(lo => this.propLandowners.some(plo => plo.accountId === lo.accountId && plo.ownership === lo.ownership))) {
          this.landowners = JSON.parse(JSON.stringify(this.propLandowners))
        }
      },
      deep: true
    }
  },

  created () {
    this.landowners = JSON.parse(JSON.stringify(this.propLandowners))
  },

  methods: {
    formatPercentage,

    landownerChosen (account, index) {
      this.landowners[index].accountId = account.accountId
      this.landowners[index].accountName = account.name
      const ownershipRef = `input-${index}`
      setTimeout(() => {
        this.$refs[ownershipRef][0].focus()
      }, 50)
    },

    addLandowner () {
      this.landowners.push({ accountId: undefined, accountName: undefined, ownership: 0 })
      const newLandownerRef = `autocomplete-${this.landowners.length - 1}`
      setTimeout(() => {
        this.$refs[newLandownerRef][0].$children[0].focus()
      }, 50)
    },

    handleTextEntry (index) {
      if (index < this.landowners.length - 1) {
        setTimeout(() => { this.$refs[`input-${index + 1}`][0].focus() }, 50)
      } else if (this.unallocatedOwnershipWarning) {
        this.addLandowner()
      }
    }
  }
}
</script>

<style scoped>
.v-sheet--outlined {
  border-color: #D15F27 !important;
}
</style>
