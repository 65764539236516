<template>
  <v-row>
    <v-col cols="12">
      <span class="subtitle-1">{{ $t('general') }}</span>
    </v-col>
    <v-col cols="12" lg="6">
      <v-select
        v-model="loadCreationSetting.receiverLoadCreationType"
        item-color="secondary"
        :label="$t('loadCreationType')"
        item-text="name"
        return-object
        :items="ReceiverLoadCreationTypes.enums"
        color="black"
        @change="updateLoadCreationSettings()"
        :readonly="!isEditing"
        :append-icon="displayDropDownIcon"
      />
    </v-col>
    <v-col cols="12" lg="6">
      <v-select
        v-model="loadCreationSetting.logContractFilterType"
        item-color="secondary"
        :label="$t('contractFilterType', { type: $t('log') })"
        :items="ReceiverLogContractFilterTypes.enums"
        item-text="name"
        return-object
        color="black"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :append-icon="displayDropDownIcon"
      />
    </v-col>
    <v-col cols="12" md="4" lg="auto">
      <v-checkbox
        v-model="loadCreationSetting.scanQR"
        :label="$t('scanQrCodes')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.scanQR)"
        :ripple="isEditing"
      />
      <v-checkbox
        v-model="loadCreationSetting.printLoopPasses"
        :label="$t('printLoopPasses')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.printLoopPasses)"
        :ripple="isEditing"
      />
      <v-checkbox
        v-model="loadCreationSetting.hidePausedContracts"
        :label="$t('hidePausedContracts')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.hidePausedContracts)"
        :ripple="isEditing"
      />
    </v-col>
    <v-col cols="12" md="4" lg="auto">
      <v-checkbox
        v-model="loadCreationSetting.forcedProductSelection"
        :label="$t('forcedProductSelection')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.forcedProductSelection)"
        :ripple="isEditing"
      />
      <v-checkbox
        v-model="loadCreationSetting.jumpToWeighInScreen"
        :label="$t('jumpToWeighInScreen')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.jumpToWeighInScreen)"
        :ripple="isEditing"
      />
    </v-col>
    <v-col cols="12" md="4" lg="auto">
      <v-checkbox
        v-model="loadCreationSetting.specifyDeckOnWeighIn"
        :label="$t('specifyDeckOnWeighIn')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.specifyDeckOnWeighIn)"
        :ripple="isEditing"
      />
      <v-checkbox
        v-model="loadCreationSetting.forcedDeckSelection"
        :label="$t('forcedDeckSelection')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :disabled="!loadCreationSetting.specifyDeckOnWeighIn && isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.forcedDeckSelection)"
        :ripple="isEditing"
      />
    </v-col>
    <v-col cols="12" md="4" lg="auto">
      <v-checkbox
        v-model="loadCreationSetting.showExternalTicket"
        :label="$t('showExternalTicket')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.showExternalTicket)"
        :ripple="isEditing"
      />
      <v-checkbox
        v-model="loadCreationSetting.showUnloadingTruckCount"
        :label="$t('showUnloadingTruckCount')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.showUnloadingTruckCount)"
        :ripple="isEditing"
      />
    </v-col>
    <v-col cols="12" md="4" lg="auto">
      <v-checkbox
        v-model="loadCreationSetting.collectAverageLength"
        :label="$t('collectAverageLength')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.collectAverageLength)"
        :ripple="isEditing"
      />
      <v-checkbox
        v-model="loadCreationSetting.showLoadCount"
        :label="$t('showLoadCount')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.showLoadCount)"
        :ripple="isEditing"
      />
    </v-col>
    <v-col cols="12" v-if="showDurations">
      <span class="subtitle-1">{{ $t('durations') }}</span>
    </v-col>
    <v-col cols="12" lg="6" v-if="showDurations">
      <v-select
        v-model="loadCreationSetting.logContractLoadCountTimeFilter"
        item-color="secondary"
        :label="$t('logContractTimeFilter')"
        :items="ReceiverLogContractLoadCountTimeFilter.enums"
        item-text="name"
        return-object
        color="black"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :append-icon="displayDropDownIcon"
      />
    </v-col>
    <v-col cols="12" lg="6" v-if="showDurations && !displayDayDuration">
      <v-select
        v-model="loadCreationSetting.loadCountDurationStartOfWeek"
        item-color="secondary"
        :label="$t('weeklyStartingOn')"
        :items="SystemDayOfWeek.enums"
        item-text="name"
        return-object
        color="black"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :append-icon="displayDropDownIcon"
      />
    </v-col>
    <v-col cols="12" lg="6" v-if="showDurations && displayDayDuration">
      <v-text-field
        type="number"
        data-testid="printer-log"
        :label="$t('lookBackDurationDays')"
        outlined color="secondary"
        v-model="loadCreationSetting.loadCountDurationDays"
        hide-spin-buttons
        @change="updateLoadCreationSettings"
        :rules="[rules.required, rules.integer, rules.validNumericRange(durationBoundaries.lower, durationBoundaries.upper)]"
        :readonly="!isEditing"
      />
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="3" class="mr-16">
          <span class="subtitle-1">{{ $t('avgLengthLimitEntry') }}</span>
          <v-row style="padding-top: 20px;">
            <v-col>
              <v-text-field
              type="number"
              data-testid="avgLengthMin"
              :label="$t('minimum')"
              outlined color="secondary"
              v-model="loadCreationSetting.averageLengthMinimum"
              hide-spin-buttons
              @change="updateLoadCreationSettings"
              :rules="[rules.decimalTwoPlacesBetweenOrBlankWithMessage(lengthBoundaries.lower, (loadCreationSetting.averageLengthMaximum ? loadCreationSetting.averageLengthMaximum : lengthBoundaries.upper))]"
              :readonly="!isEditing"
              />
            </v-col>
            <v-col>
              <v-text-field
              type="number"
              data-testid="avgLengthMax"
              :label="$t('maximum')"
              outlined color="secondary"
              v-model="loadCreationSetting.averageLengthMaximum"
              hide-spin-buttons
              @change="updateLoadCreationSettings"
              :rules="[rules.decimalTwoPlacesBetweenOrBlankWithMessage((loadCreationSetting.averageLengthMinimum ? loadCreationSetting.averageLengthMinimum : lengthBoundaries.lower), lengthBoundaries.upper)]"
              :readonly="!isEditing"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3">
          <span class="subtitle-1">{{ $t('pieceCountLimitEntry') }}</span>
          <v-row style="padding-top: 20px;">
            <v-col>
              <v-text-field
              type="number"
              data-testid="pieceCountMin"
              :label="$t('minimum')"
              outlined color="secondary"
              v-model="loadCreationSetting.pieceCountMinimum"
              hide-spin-buttons
              @change="updateLoadCreationSettings"
              :rules="[rules.positiveIntegerBetweenOrBlank(pieceCountBoundaries.lower, (loadCreationSetting.pieceCountMaximum ? loadCreationSetting.pieceCountMaximum : pieceCountBoundaries.upper))]"
              :readonly="!isEditing"
              />
            </v-col>
            <v-col>
              <v-text-field
              type="number"
              data-testid="pieceCountMax"
              :label="$t('maximum')"
              outlined color="secondary"
              v-model="loadCreationSetting.pieceCountMaximum"
              hide-spin-buttons
              @change="updateLoadCreationSettings"
              :rules="[rules.positiveIntegerBetweenOrBlank((loadCreationSetting.pieceCountMinimum ? loadCreationSetting.pieceCountMinimum : pieceCountBoundaries.lower), pieceCountBoundaries.upper)]"
              :readonly="!isEditing"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="displayByproductSettings">
      <span class="subtitle-1">{{ $t('byproductLoadSettings') }}</span>
    </v-col>
    <v-col cols="12" lg="6" v-if="displayByproductSettings">
      <v-select
        v-model="loadCreationSetting.byproductSaleContractFilterType"
        item-color="secondary"
        :label="$t('byproductSaleContractFilterType')"
        item-text="name"
        return-object
        :items="ReceiverByproductSaleContractFilterTypes.enums"
        color="black"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :append-icon="displayDropDownIcon"
      />
    </v-col>
    <v-col cols="12" lg="6" v-if="displayByproductSettings">
      <v-select
        v-model="loadCreationSetting.byproductPurchaseContractFilterType"
        item-color="secondary"
        :label="$t('byproductPurchaseContractFilterType')"
        :items="ReceiverByproductPurchaseContractFilterTypes.enums"
        item-text="name"
        return-object
        color="black"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :append-icon="displayDropDownIcon"
      />
    </v-col>
    <v-col cols="12" md="4" lg="auto" v-if="displayByproductSettings">
      <v-checkbox
        v-model="loadCreationSetting.createByproductDeliveryLoads"
        :label="$t('createByproductDeliveryLoads')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.createByproductDeliveryLoads)"
        :ripple="isEditing"
      />
    </v-col>
    <v-col cols="12" md="4" lg="auto" v-if="displayByproductSettings">
      <v-checkbox
        v-model="loadCreationSetting.createByproductPickupLoads"
        :label="$t('createByproductPickupLoads')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.createByproductPickupLoads)"
        :ripple="isEditing"
      />
    </v-col>
    <v-col cols="12" md="4" lg="auto" v-if="displayByproductSettings">
      <v-checkbox
        v-model="loadCreationSetting.createByproductPurchaseLoads"
        :label="$t('createByproductPurchaseLoads')"
        @change="updateLoadCreationSettings"
        :readonly="!isEditing"
        :on-icon=determineCheckBoxTrueDisplay
        :off-icon="determineCheckBoxFalseDisplay"
        :color="determineCheckBoxEditingColor(loadCreationSetting.createByproductPurchaseLoads)"
        :ripple="isEditing"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { ReceiverLoadCreationTypes, ReceiverLogContractFilterTypes, ReceiverByproductSaleContractFilterTypes, ReceiverByproductPurchaseContractFilterTypes, ReceiverLogContractLoadCountTimeFilter, SystemDayOfWeek } from '@/utils/Enumerations.js'
import fieldRules from '@/utils/rules.js'

const DURATION_UPPER_BOUND = 30
const DURATION_LOWER_BOUND = 1
const LENGTH_MINIMUM = 1.0
const LENGTH_MAXIMUM = 999999.99
const PIECE_MINIMUM = 1
const PIECE_MAXIMUM = 2500

export default {
  name: 'LoadCreationSettingsForm',

  watch: {
    'loadCreationSetting.specifyDeckOnWeighIn': {
      handler (value) {
        if (!value) {
          this.loadCreationSetting.forcedDeckSelection = false
          this.updateLoadCreationSettings()
        }
      }
    }
  },

  props: {
    propSettings: {
      type: Object,
      default: undefined
    },
    isEditing: {
      type: Boolean,
      default: true
    },
    displayDetails: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    loadCreationSetting: {
      receiverLoadCreationType: ReceiverLoadCreationTypes.LogsOnly,
      receiverLoadCreationSettingId: undefined,
      scanQR: false,
      printLoopPasses: false,
      jumpToWeighInScreen: false,
      specifyDeckOnWeighIn: false,
      showExternalTicket: false,
      forcedProductSelection: false,
      forcedDeckSelection: false,
      collectAverageLength: false,
      logContractLoadCountTimeFilter: ReceiverLogContractLoadCountTimeFilter.Day,
      logContractFilterType: ReceiverLogContractFilterTypes.Account,
      byproductSaleContractFilterType: ReceiverByproductSaleContractFilterTypes.Account,
      byproductPurchaseContractFilterType: ReceiverByproductPurchaseContractFilterTypes.FromAccountTract,
      createByproductDeliveryLoads: true,
      createByproductPickupLoads: false,
      createByproductPurchaseLoads: false,
      showUnloadingTruckCount: false,
      showLoadCount: false,
      hidePausedContracts: false,
      averageLengthMinimum: undefined,
      averageLengthMaximum: undefined,
      pieceCountMinimum: undefined,
      pieceCountMaximum: undefined,
      loadCountDurationDays: 1,
      loadCountDurationStartOfWeek: SystemDayOfWeek.Sunday
    },
    rules: fieldRules.rules,
    ReceiverLoadCreationTypes,
    ReceiverLogContractFilterTypes,
    ReceiverByproductSaleContractFilterTypes,
    ReceiverByproductPurchaseContractFilterTypes,
    ReceiverLogContractLoadCountTimeFilter,
    SystemDayOfWeek
  }),

  created () {
    if (this.propSettings) this.loadCreationSetting = JSON.parse(JSON.stringify(this.propSettings))
  },

  computed: {
    ...mapGetters('receiver-settings', ['loading']),
    displayByproductSettings () {
      return this.displayDetails.byproducts || this.displayDetails.both
    },
    displayDropDownIcon () {
      return this.isEditing ? '$dropdown' : ''
    },
    determineCheckBoxTrueDisplay () {
      return this.isEditing ? '$checkboxOn' : 'mdi-check'
    },
    determineCheckBoxFalseDisplay () {
      return this.isEditing ? '$checkboxOff' : 'mdi-close'
    },
    showDurations () {
      return this.loadCreationSetting.showLoadCount
    },
    durationBoundaries () {
      return {
        lower: DURATION_LOWER_BOUND,
        upper: DURATION_UPPER_BOUND
      }
    },
    lengthBoundaries () {
      return {
        lower: LENGTH_MINIMUM,
        upper: LENGTH_MAXIMUM
      }
    },
    pieceCountBoundaries () {
      return {
        lower: PIECE_MINIMUM,
        upper: PIECE_MAXIMUM
      }
    },
    displayDayDuration () {
      return this.loadCreationSetting.logContractLoadCountTimeFilter.value === ReceiverLogContractLoadCountTimeFilter.Day.value
    }
  },

  methods: {
    updateLoadCreationSettings () {
      this.$emit('updateLoadCreationSettings', this.loadCreationSetting)
    },
    determineCheckBoxEditingColor (value) {
      return this.isEditing ? undefined : value ? 'success' : 'red'
    }
  }

}
</script>
